<template>
  <div class="ai-box">
    <div v-show="isTitle" class="title">{{ title }}</div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'isTitle']
}
</script>
<style lang="less" scoped>
.ai-box{
  background: #F5F7FA;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #CFD3E3;
  position: relative;
  .title{
    position: absolute;
    top: -16px;
    left: 34px;
    background: #FFFFFF;
    border: 1px solid #CFD3E3;
    z-index: 0;
    padding: 0 30px;
    line-height: 30px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
  }
}
</style>
