<template>
  <div class="ai">
    <div class="top-box">
      <div>
        <el-select ref="selectRefAi" v-model="projectId" @change="projectGroupChange" @visible-change="visibleChange">
          <el-option v-for="(item, i) in projectGroupList" :key="i" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <div class="btn-box">
        <el-button @click="openApp(1)">训练管理</el-button>
        <el-button @click="openApp(2)">开发环境</el-button>
        <el-button @click="openApp(3)">镜像仓库</el-button>
      <!-- <el-button @click="openApp(4)">模型管理</el-button>
      <el-button @click="openApp(5)">文件管理</el-button> -->
      </div>
    </div>
    <div class="flex-space-style margins">
      <ai-box :title="'资源统计'" :is-title="isTitle" class="content-box space">
        <div class="tips-style">
          <el-tooltip
            class="item"
            effect="light"
            placement="bottom-end"
          >
            <template #content>
              资源统计为统计该项目的配额及资源利用率。<br>
              占用：该数据为实际占用量，如pending状态任务则不计算在此数据内。<br>
              申请：该数据为申请占用量，pending状态任务计算在此数据内。
            </template>
            <svg-icon icon-class="tips" />
          </el-tooltip>
        </div>
        <div class="view" style="display:flex">
          <div class="total-title">
            <div style="padding-bottom:8px;">总量</div>
            <div>占用/申请</div>
          </div>
          <div style="width:100%; height:100%; position: relative;">
            <div class="charts-title">CPU使用情况</div>
            <div id="cpuUsageChart" class="pie-echart" style="width:100%; height:170px;" />
            <div class="charts-total" style="margin-bottom:8px;"><span>{{ quota.cpuLimit }}（核）</span></div>
            <div class="charts-total">{{ quota.cpuUsing }} / {{ quota.cpuCount }}</div>
            <!-- <div class="usage">
              <div class="usage-title">可用</div>
              <div>无限制</div>
            </div> -->
          </div>
          <div style="width:100%; height:100%; position: relative;">
            <div class="charts-title">GPU使用情况</div>
            <div id="gpuUsageChart" class="pie-echart" style="width:100%; height:170px" />
            <div class="charts-total" style="margin-bottom:8px;"><span>{{ quota.gpuLimit }}（卡）</span></div>
            <div class="charts-total">{{ quota.gpuUsing }} / {{ quota.gpuCount }}</div>
            <!-- <div class="usage">
              <div class="usage-title">可用</div>
              <div>无限制</div>
            </div> -->
          </div>
          <div style="width:100%; height:100%; position: relative;">
            <div class="charts-title">存储使用情况</div>
            <div id="usageChart" class="pie-echart" style="width:100%; height:170px" />
            <div class="charts-total" style="margin-bottom:8px;"><span>{{ quota.memLimit }}（GB）</span></div>
            <div class="charts-total">{{ quota.memUsing }} / {{ quota.memCount }}</div>
            <!-- <div class="usage">
              <div class="usage-title">可用</div>
              <div>无限制</div>
            </div> -->
          </div>
        </div>
      </ai-box>
      <ai-box :title="'资源统计'" :is-title="isTitle" class="content-box">
        <div v-loading="isNodeLoading" class="src-detail">
          <div class="detail-top">
            <div v-for="(item,i) in srcDetailList" :key="i" class="detail-box">
              <div class="box-title">{{ item.gpuType }}</div>
              <div class="box-content">
                <div style="width:50%;">
                  <div class="content-title">节点数</div>
                  <div>{{ item.nodeAllocated }}</div>
                </div>
                <div>
                  <div class="content-title">GPU卡数</div>
                  <div><span style="color:#00A870;">{{ item.gpuAllocated-item.gpuCount }}</span> / <span style="color:#165DFF;">{{ item.gpuAllocated }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="detail-bottom">
            <el-table
              ref="nodeTable"
              v-el-table-infinite-scroll="loadNode"
              :data="srcData"
              style="width: 100%;"
              height="150px"
            >
              <el-table-column prop="nodeName" label="节点名称" show-overflow-tooltip />
              <el-table-column
                prop="allocatablelCPU"
                label="CPU核数"
                show-overflow-tooltip
              />
              <el-table-column
                prop="allocatablelGPU"
                label="GPU卡数"
                show-overflow-tooltip
              />
              <el-table-column
                prop="gpuType"
                label="GPU型号"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </ai-box>
    </div>
    <div class="flex-space-style bottom-style margins">
      <ai-box :title="'开发环境'" :is-title="isTitle" class="content-box space">
        <div v-loading="isDevLoading" class="development">
          <div class="tasks-top">
            <div v-for="(item,i) in devList" :key="i" class="tasks-box">
              <div class="box-content">
                <div>
                  <svg-icon :icon-class="item.icon" />
                </div>
                <div>
                  <div class="content-title">{{ item.title }}</div>
                  <div><span class="number">{{ item.number }}</span><span class="unit">{{ item.unit }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="tasks-bottom">
            <el-table
              ref="devTable"
              v-el-table-infinite-scroll="loadDev"
              :data="devData"
              style="width: 100%;"
              height="150px"
            >
              <el-table-column prop="envName" label="环境名称" show-overflow-tooltip />
              <el-table-column
                prop="status"
                label="状态"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div
                    v-if="scope.row.status === 'Running' || scope.row.status === 'active'"
                    style="color: #165DFF"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else-if="scope.row.status === 'pending'"
                    style="color: #FD8E3F"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else-if="scope.row.status === 'stop' || scope.row.status === 'pause'"
                    style="color: #909399"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else
                    style="color: #FF1E1E"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nodeName"
                label="节点"
                show-overflow-tooltip
              />
              <el-table-column
                prop="creationTime"
                label="创建时间"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </ai-box>
      <ai-box :title="'训练任务'" :is-title="isTitle" class="content-box">
        <div v-loading="isJobLoading" class="training-tasks">
          <div class="tasks-top">
            <div v-for="(item,i) in tasksList" :key="i" class="tasks-box">
              <div class="box-content">
                <div>
                  <svg-icon :icon-class="item.icon" />
                </div>
                <div>
                  <div class="content-title">{{ item.title }}</div>
                  <div><span class="number">{{ item.number }}</span><span class="unit">{{ item.unit }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="tasks-bottom">
            <el-table
              ref="jobTable"
              v-el-table-infinite-scroll="loadJob"
              :data="tasksData"
              style="width: 100%;"
              height="150px"
            >
              <el-table-column prop="taskName" label="任务名称" show-overflow-tooltip />
              <el-table-column
                prop="status"
                label="状态"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div
                    v-if="scope.row.status === 'Running'"
                    style="color: #165DFF"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else-if="scope.row.status === 'Pending'"
                    style="color: #FD8E3F"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else-if="scope.row.status === 'stop' || scope.row.status === 'pause'"
                    style="color: #909399"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                  <div
                    v-else
                    style="color: #FF1E1E"
                  >
                    <div>{{ scope.row.status }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="runningCost"
                label="运行时长(m)"
                show-overflow-tooltip
              />
              <el-table-column
                prop="nodeName"
                label="节点"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </ai-box>
    </div>
    <!-- <div class="btn-box">
      <el-button @click="openApp(1)">训练管理</el-button>
      <el-button @click="openApp(2)">开发环境</el-button>
      <el-button @click="openApp(3)">镜像仓库</el-button>
      <el-button @click="openApp(4)">模型管理</el-button>
      <el-button @click="openApp(5)">文件管理</el-button>
    </div> -->
  </div>
</template>

<script>
import { getDevlopmentenv, getJob, getDashboardNode, getDashboardGpuType, getDashboardQuota, getUserProjectGroup } from '@/api/ai'
import aiBox from '../components/AiBox.vue'
import * as echarts from 'echarts'
import { markRaw, resolveComponent } from 'vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
export default {
  components: { aiBox },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      tipsContent: `资源统计为统计该项目的配额及资源利用率。
        占用：该数据为实际占用量，如pending状态任务则不计算在此数据内。
        申请：该数据为申请占用量，pending状态任务计算在此数据内。`,
      projectGroupList: [],
      projectId: null,
      myChart: null,
      myCpuChart: null,
      myGpuChart: null,
      isTitle: true,
      // 资源组详情
      isNodeLoading: false,
      srcDetailList: [],
      srcData: [],
      pageSizeNode: 10,
      pageNumNode: 1,
      pagenumberNode: 0,
      totalPageNode: 0,
      pageSizesNode: [2, 3, 5, 10, 20],
      // 训练任务
      isJobLoading: false,
      pageSizeJob: 10,
      pageNumJob: 1,
      pagenumberJob: 0,
      totalPageJob: 0,
      pageSizesJob: [2, 3, 5, 10, 20],
      tasksList: [{
        icon: 'tasks-total',
        title: '任务总量',
        number: '1',
        unit: '个'
      }, {
        icon: 'tasks-run',
        title: '运行中',
        number: '1',
        unit: '个'
      }, {
        icon: 'tasks-wait',
        title: '等待中',
        number: '1',
        unit: '个'
      }, {
        icon: 'tasks-cpu',
        title: 'CPU申请',
        number: '8',
        unit: '核'
      }, {
        icon: 'tasks-gpu',
        title: 'GPU申请',
        number: '8',
        unit: '卡'
      }],
      tasksData: [],
      // 开发环境
      pageSizeDev: 10,
      pageNumDev: 1,
      pagenumberDev: 0,
      totalPageDev: 0,
      pageSizesDev: [2, 3, 5, 10, 20],
      isDevLoading: false,
      devList: [{
        icon: 'dev-total',
        title: '开发环境数量',
        number: '0',
        unit: '个'
      }, {
        icon: 'dev-active',
        title: '活跃数',
        number: '0',
        unit: '个'
      }, {
        icon: 'tasks-cpu',
        title: 'CPU申请',
        number: '0',
        unit: '核'
      }, {
        icon: 'tasks-gpu',
        title: 'GPU申请',
        number: '0',
        unit: '卡'
      }],
      devData: [],
      data: [],
      data1: [],
      data2: [],
      // 配额
      cpuCount: '0',
      gpuCount: '1',
      memCount: '1',
      cpuAbleCount: null,
      gpuAbleCount: null,
      memAbleCount: null,
      timer: null,
      quota: {}
    }
  },
  mounted() {
    this.getProjectGroup()
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    const echarts = require('echarts')
    const chartPiesCpu = echarts.init(document.getElementById('cpuUsageChart'))
    const chartPiesGpu = echarts.init(document.getElementById('gpuUsageChart'))
    const chartPiesMem = echarts.init(document.getElementById('usageChart'))
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('ai').length - 1
    erd.listenTo(document.getElementsByClassName('ai')[i], function(element) {
      try {
        setTimeout(() => {
          chartPiesCpu.resize()
          chartPiesGpu.resize()
          chartPiesMem.resize()
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 打开按钮对应应用
    openApp(index) {
      if (index === 1) {
        this.$store.dispatch('core/openApp', 8889162036467451)
      } else if (index === 2) {
        this.$store.dispatch('core/openApp', 8889162036467452)
      } else if (index === 3) {
        this.$store.dispatch('core/openApp', 8889162036467453)
      } else if (index === 4) {
        this.$store.dispatch('core/openApp', 8889162036467454)
      } else {
        this.$store.dispatch('core/openApp', 8889162036467455)
      }
    },
    // 项目组固定底部按钮
    visibleChange(visible) {
      // 下拉框显示隐藏
      if (visible) {
        const ref = this.$refs.selectRefAi
        // 拿到下拉选项的对象
        let popper = ref.popperPaneRef
        // 在拿到它的DOM元素
        if (popper.$el) popper = popper.$el
        // 判断是否有添加按钮
        if (!Array.from(popper.children).some(v => v.className === 'select-btn-box')) {
          // 插入按钮
          const el = document.createElement('div')
          const confirmBtn = document.createElement('button')
          el.className = 'select-btn-box'
          el.style = `
                       font-size: 14px;
                       font-weight: 600;
                       height: 42px;
                       background: #fff;
                       color: #409eff;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       cursor: pointer;
                       border-top:1px solid rgb(240 242 245)
                 `
          confirmBtn.className = 'select-confirm-mini-btn'
          confirmBtn.innerText = '项目组管理'
          confirmBtn.style = `background: #fff;`
          el.appendChild(confirmBtn)
          // 调用确认和取消函数
          // cancelBtn.onclick = () => cancelHandle()
          confirmBtn.onclick = () => this.openProjectGroup()
          el.addEventListener('mouseenter', function() {
            el.style.backgroundColor = '#F7F8FA'
            confirmBtn.style = `background: #F7F8FA;`
          })
          el.addEventListener('mouseleave', function() {
            el.style.backgroundColor = '#fff'
            confirmBtn.style = `background: #fff;`
          })
          popper.appendChild(el)
        }
      }
    },
    openProjectGroup() {
      this.$refs.selectRefAi.visible = false
      this.$store.dispatch('core/openApp', 8889162036467499)
    },
    // 项目组列表改变
    projectGroupChange(val) {
      this.tasksData = []
      this.devData = []
      this.srcData = []
      this.projectId = val
      this.pageNumDev = 1
      this.pageNumJob = 1
      this.pageNumNode = 1
      this.totalPageDev = 0
      this.totalPageJob = 0
      this.totalPageNode = 0
      sessionStorage.projectGroupId = this.projectId
      this.$nextTick(() => {
        this.getDevlopmentenv()
        this.getJob()
        this.getData()
        this.getNode()
      })
    },
    getProjectGroup() {
      this.projectGroupList = []
      getUserProjectGroup().then((response) => {
        this.projectGroupList = response.data.content
        this.projectId = this.projectGroupList[0].id
        sessionStorage.projectGroupId = this.projectId
        this.$nextTick(() => {
          this.getDevlopmentenv()
          this.getJob()
          this.getData()
          this.getNode()
        })
        const that = this
        this.timer = setInterval(() => {
          that.getDevRefreshData()
          that.getJobRefreshData()
          that.getNodeRefreshData()
          that.getData()
        }, 10000)
      })
    },
    // 获取dev刷新数据
    getDevRefreshData() {
      // 获取开发环境列表数据
      const dev = {
        pagenum: this.pageNumDev,
        pagesize: this.pageSizeDev,
        projectId: this.projectId
      }
      getDevlopmentenv(dev).then((response) => {
        if (this.pageNumDev == 1) {
          this.devData = response.data.content
        } else {
          this.devData = this.devData.concat(response.data.content)
        }
        // this.devData = response.data.content
        this.devData = this.devData.concat(response.data.content)
        this.devList[0].number = response.data.devEnvResource.devEnvCount
        this.devList[1].number = response.data.devEnvResource.activeDevEnvCount
        this.devList[2].number = response.data.devEnvResource.cpuUsage
        this.devList[3].number = response.data.devEnvResource.gpuUsage
        this.pagenumberDev = response.data.totalpages
        this.totalPageDev = response.data.totalelements
        this.isDevLoading = false
        this.$nextTick(() => {
          this.devData = this.unique(this.devData, 'envName')
        })
      }).catch(() => {
        this.isDevLoading = false
      })
    },
    // 获取任务刷新数据
    getJobRefreshData() {
      // 获取开发环境列表数据
      const dev = {
        pagenum: this.pageNumJob,
        pagesize: this.pageSizeJob,
        projectId: this.projectId
      }
      getJob(dev).then((response) => {
        if (this.pageNumJob == 1) {
          this.tasksData = response.data.content
        } else {
          this.tasksData = this.tasksData.concat(response.data.content)
        }
        this.tasksList[0].number = response.data.jobResourceVO.taskCount
        this.tasksList[1].number = response.data.jobResourceVO.runningCount
        this.tasksList[2].number = response.data.jobResourceVO.pendingCount
        this.tasksList[3].number = response.data.jobResourceVO.cpuCount
        this.tasksList[4].number = response.data.jobResourceVO.gpuCount
        this.pagenumberJob = response.data.totalpages
        this.totalPageJob = response.data.totalelements
        this.isJobLoading = false
        this.$nextTick(() => {
          this.tasksData = this.unique(this.tasksData, 'taskName')
        })
      }).catch(() => {
        this.isJobLoading = false
      })
    },
    getData() {
      const dev = {
        projectId: this.projectId
      }
      getDashboardQuota(dev).then((response) => {
        this.quota = response.data
        this.cpuCount = response.data.cpuCount
        this.cpuAbleCount = response.data.cpuLimit - response.data.cpuCount
        this.gpuCount = response.data.gpuCount
        this.gpuAbleCount = response.data.gpuLimit - response.data.gpuCount
        this.memAbleCount = response.data.memLimit - response.data.memCount
        // 0的时候则显示无限制
        if (response.data.cpuLimit === 0) {
          this.quota.cpuLimit = '无限制'
          this.cpuAbleCount = '无限制'
        }
        if (response.data.gpuLimit === 0) {
          this.quota.gpuLimit = '无限制'
          this.gpuAbleCount = '无限制'
        }
        if (response.data.memLimit === 0) {
          this.quota.memLimit = '无限制'
          this.memAbleCount = '无限制'
        }
        this.quota.memCount = parseFloat((response.data.memCount / 1024 / 1024 / 1024).toFixed(2))
        this.quota.memUsing = parseFloat((response.data.memUsing / 1024 / 1024 / 1024).toFixed(2))
        this.memCount = parseFloat((response.data.memCount / 1024 / 1024 / 1024).toFixed(2))
        this.data = [{
          name: '已用',
          value: this.cpuCount
        }, {
          name: '可用',
          value: 1000000000
        }]
        this.data1 = [{
          name: '已用',
          value: this.gpuCount
        }, {
          name: '可用',
          value: 1000000000
        }]
        this.data2 = [{
          name: '已用',
          value: this.memCount
        }, {
          name: '可用',
          value: 1000000000
        }]
        this.$nextTick(() => {
          this.getUsageEcharts()
        })
        // this.isDevLoading = false
      }).catch(() => {
        // this.isDevLoading = false
      })
    },
    // 数组去重
    unique(arr, val) {
      const res = new Map()
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
    },
    // 开发环境
    getDevlopmentenv() {
      this.isDevLoading = true
      this.getDevRefreshData()
    },
    // 开发环境分页
    loadDev() {
      if (!this.isDevLoading) {
        this.isDevLoading = true
        if (this.devData.length < this.totalPageDev) {
          this.pageNumDev++
          this.getDevlopmentenv()
        } else {
          // this.$message('已加载完所有的数据！')
          this.isDevLoading = false
        }
      }
    },
    // 训练任务
    getJob() {
      this.isJobLoading = true
      this.getJobRefreshData()
    },
    // 训练任务分页
    loadJob() {
      if (!this.isJobLoading) {
        this.isJobLoading = true
        if (this.tasksData.length < this.totalPageJob) {
          this.pageNumJob++
          this.getJob()
        } else {
          // this.$message('已加载完所有的数据！')
          this.isJobLoading = false
        }
      }
    },
    // 资源组详情
    getNodeRefreshData() {
      // 获取开发环境列表数据
      const dev = {
        pagenum: this.pageNumNode,
        pagesize: this.pageSizeNode
      }
      getDashboardNode(dev).then((response) => {
        // this.srcData = this.srcData.concat(response.data.content)
        if (this.pageNumNode == 1) {
          this.srcData = response.data.content
        } else {
          this.srcData = this.srcData.concat(response.data.content)
        }
        this.pagenumberNode = response.data.totalpages
        this.totalPageNode = response.data.totalelements
        this.isNodeLoading = false
        this.$nextTick(() => {
          this.srcData = this.unique(this.srcData, 'nodeName')
        })
      })
      getDashboardGpuType().then((response) => {
        this.srcDetailList = response.data
        this.isNodeLoading = false
      }).catch(() => {
        this.isNodeLoading = false
      })
    },
    getNode() {
      this.isNodeLoading = true
      this.getNodeRefreshData()
    },
    // 训练任务分页
    loadNode() {
      if (!this.isNodeLoading) {
        this.isNodeLoading = true
        if (this.srcData.length < this.totalPageNode) {
          this.pageNumNode++
          this.getNode()
        } else {
          // this.$message('已加载完所有的数据！')
          this.isNodeLoading = false
        }
      }
    },
    // GPU\CPU\存储使用情况图表
    getUsageEcharts() {
      this.myChart = markRaw(echarts.init(document.getElementById('usageChart')))
      this.myCpuChart = markRaw(echarts.init(document.getElementById('cpuUsageChart')))
      this.myGpuChart = markRaw(echarts.init(document.getElementById('gpuUsageChart')))
      const option = {
        color: ['#437AEC', '#437AEC'],
        title: {
          text: '可用',
          left: 'center',
          top: '38%',
          textStyle: {
            color: '#909399',
            fontSize: 14
          },
          subtext: this.cpuAbleCount + '(核)',
          subtextStyle: {
            color: '#303133',
            fontSize: 16,
            fontWeight: 500,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{b}:{c}GB({d}%)'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: 'CPU使用情况',
            type: 'pie',
            radius: ['75%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.data
          }
        ]
      }
      const option1 = {
        color: ['#1798FF', '#1798FF'],
        title: {
          text: '可用',
          left: 'center',
          top: '38%',
          textStyle: {
            color: '#909399',
            fontSize: 14
          },
          subtext: this.gpuAbleCount + '(卡)',
          subtextStyle: {
            color: '#303133',
            fontSize: 16,
            fontWeight: 500,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{b}:{c}GB({d}%)'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: 'GPU使用情况',
            type: 'pie',
            radius: ['75%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.data1
          }
        ]
      }
      const option2 = {
        color: ['#15C9FF', '#15C9FF'],
        title: {
          text: '可用',
          left: 'center',
          top: '38%',
          textStyle: {
            color: '#909399',
            fontSize: 14
          },
          subtext: this.memAbleCount + '(GB)',
          subtextStyle: {
            color: '#303133',
            fontSize: 16,
            fontWeight: 500,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{b}:{c}GB({d}%)'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '存储使用情况',
            type: 'pie',
            radius: ['75%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.data2
          }
        ]
      }
      this.myChart.setOption(option2)
      this.myCpuChart.setOption(option)
      this.myGpuChart.setOption(option1)
    }
  }

}
</script>

<style lang="less" scoped>
.ai{
  padding: 14px 0;
  padding-bottom: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  // min-width: 1426px;
  overflow: auto;
  .flex-space-style{
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-table{
    z-index: 0;
  }
  .margins{
    margin: 0 24px;
  }
  ::v-deep .el-loading-mask{
    z-index: 9;
  }
  .top-box{
    display: flex;
    justify-content: space-between;
    // text-align: left;
    margin: 0  24px 40px 24px;
    width: calc(100% - 48px);
    min-width: 1378px;
    ::v-deep .el-input__wrapper{
      height: 32px;
      line-height: 32px;
      background-color: #F7F8FA;
      border: none;
      box-shadow: none;
    }
    ::v-deep .el-select{
      width: 300px;
      .el-icon{
        color: #4E5969;
        font-size: 12px;
      }
      .el-input__wrapper.is-focus{
        box-shadow: none !important;
      }
    }
    ::v-deep .el-select .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
    ::v-deep .el-select .el-select__wrapper.is-focus{
      box-shadow: none !important;
    }
    ::v-deep .el-select .el-input.is-focus .el-input__inner{
      box-shadow: none !important;
      background-color: #F7F8FA !important;
    }
  }
  .space{
    margin-right: 16px;
  }
  .bottom-style{
    margin: 31px 24px;
  }
  .content-box{
    width: 50%;
    min-width: 681px;
    .tips-style{
      text-align: right;
      margin-top: 5px;
      margin-right: 5px;
      border: 1px solid transparent !important;
      ::v-deep.svg-icon.el-tooltip__trigger{
        border: 1px solid transparent !important;
      }
      ::v-deep .el-tooltip__trigger:focus {
        outline: none;
      }
      .svg-icon{
        cursor: pointer;
        width: 22px;
        height: 22px;
        border: 1px solid transparent !important;
      }
    }
    ::v-deep .el-table th.el-table__cell{
          background-color: #fff;
          padding: 6.05px 0;
        }
        ::v-deep .el-table__footer-wrapper, .el-table__header-wrapper{
          border-radius: 6px 6px 0 0;
        }
        ::v-deep .el-table .el-table__cell{
          padding: 7.5px 0;
        }
        ::v-deep .el-table .cell{
          color: #909399;
          font-size: 14px;
          font-weight: 400;
        }
        ::v-deep .el-table tbody .cell{
          color: #303133;
        }
    .view{
      padding: 30px 33px;
      padding-left: 62px;
      position: relative;
      .total-title{
        position: absolute;
        top: 258px;
        left: 20px;
        font-weight: 500;
        color: #303133;
        font-size: 14px;
      }
      .usage{
        position: absolute;
        top: 55%;
        left: 40%;
        font-weight: 500;
        color: #303133;
        .usage-title{
          color: #909399;
          font-size: 14px;
          font-weight: 400;
          padding-left: 7px;
        }
      }
      .charts-title{
        text-align: center;
        margin-bottom: 16px;
        height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
        line-height: 24px;
      }
      .charts-total{
        text-align: center;
        padding-top: 16px;
        height: 22px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        line-height: 22px;
        .total-title{
          color: #909399;
          padding-right: 16px;
        }
      }
    }
    .src-detail,
    .training-tasks,
    .development{
      padding: 32px 10px 16px 20px;
      .detail-bottom,
      .tasks-bottom{
        background: #FFFFFF;
        border-radius: 6px;
        margin-right: 10px;
        padding: 11px 26px;
      }
      .detail-top,
      .tasks-top{
        display: flex;
        flex-wrap: wrap;
        .tasks-box{
          margin-right: 30px;
          margin-bottom: 16px;
        }
        .box-content{
          display: flex;
          .svg-icon{
            width: 48px;
            height: 48px;
            margin-right: 8px;
          }
        }
        .tasks-box,
        .detail-box{
          font-family: PingFang SC-Regular, PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #303133;
          .content-title{
            height: 22px;
            font-weight: 400;
            color: #909399;
            line-height: 22px;
          }
          .number{
            font-size: 16px;
          }
          .unit{
            font-size: 12px;
            font-weight: 400;
          }
        }
        .detail-box{
          width: 160px;
          background: #FFFFFF;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #E7E7E7;
          margin-right: 10px;
          margin-bottom: 10px;
          .box-title{
            height: 30px;
            line-height: 30px;
            color: #FFFFFF;
            border-radius: 6px 6px 0 0;
            background: #437AEC;
            text-align: center;
            font-family: PingFang SC-Medium, PingFang SC;
          }
          .box-content{
            padding: 12px;
          }
        }
      }
    }
    .development{
      ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border-bottom: none;
      }
      ::v-deep .el-table .el-table__cell{
          padding: 8.5px 0;
        }
    }
    .training-tasks,
    .development{
      padding-right: 0;
      .content-title{
        margin-bottom: 6px;
      }
      .tasks-bottom{
        margin-right: 20px;
      }
      .box-content{
        align-items: center;
      }
    }
  }
  .btn-box{
    display: flex;
    justify-content: center;
    .el-button+.el-button{
      margin-left: 32px;
    }
    .el-button{
      background-color: #fff;
      border: 1px solid #437AEC;
      color: #437AEC;
      padding: 5px 24px;
      font-size: 14px;
    }
  }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
