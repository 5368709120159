import request from '@/utils/requestAi'
import requestAuth from '@/utils/requestAuth'
const auth = '/service-auth-provider/v2'
const authV1 = '/service-auth-provider/v1'
const k8s = '/service-kubernetes-provider/v1'
const harbor = '/service-harbor-provider'
// 获取首页数据
export function getDashboard(data) {
  return request({
    url: k8s + '/dashboard',
    method: 'get',
    params: data
  })
}
// 资源组
export function getDashboardNode(data) {
  return request({
    url: k8s + '/node/resource',
    method: 'get',
    params: data
  })
}
// 按gpu类型统计
export function getDashboardGpuType() {
  return request({
    url: k8s + '/node/gpu/type',
    method: 'get'
  })
}
// 配额
export function getDashboardQuota(data) {
  return request({
    url: k8s + '/quota',
    method: 'get',
    params: data
  })
}

// 镜像
// 获取所有镜像
export function getImagesList(repo, data) {
  return request({
    url: harbor + '/harbor/' + repo + '/images',
    method: 'get',
    params: data
  })
}

// 获取镜像版本信息
export function getImagesVersionList(repo, image, data) {
  return request({
    url: harbor + '/harbor/' + repo + '/' + image + '/versions',
    method: 'get',
    params: data
  })
}

// 开发环境
// 查询开发环境
export function getDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'get',
    params: data
  })
}

// 查询端口占用情况
export function checkPort(data) {
  return request({
    url: k8s + '/devlopmentenv/port/duplicate',
    method: 'get',
    params: data
  })
}

// 创建开发环境
export function createDevlopmentenv(data, params) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'post',
    data,
    params: params
  })
}
// 删除开发环境
export function deleteDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv',
    method: 'delete',
    params: data
  })
}

// 挂起、启动开发环境
export function actionDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv/action',
    method: 'patch',
    params: data
  })
}
// 修改副本数
export function updateDevlopmentenv(data) {
  return request({
    url: k8s + '/devlopmentenv/replicase',
    method: 'patch',
    params: data
  })
}
// 获取开发环境pod
export function getDevlopmentenvPod(data) {
  return request({
    url: k8s + '/devlopmentenv/pods',
    method: 'get',
    params: data
  })
}

// 获取开发环境资源利用率
export function getDevlopmentenvSrcDeatail(data) {
  return request({
    url: k8s + '/devlopmentenv/resource/usage/hist',
    method: 'get',
    params: data
  })
}

// 开发环境详情
export function getDevlopmentenvDetail(data) {
  return request({
    url: k8s + '/devlopmentenv/detail',
    method: 'get',
    params: data
  })
}

// 训练管理
// 查询训练任务
export function getJob(data) {
  return request({
    url: k8s + '/job',
    method: 'get',
    params: data
  })
}

// 创建训练任务
export function createJob(data, params) {
  return request({
    url: k8s + '/job',
    method: 'post',
    data,
    params: params
  })
}
// 删除
export function deleteJob(data) {
  return request({
    url: k8s + '/job',
    method: 'delete',
    params: data
  })
}
// 获取资源组列表
export function getResourceList() {
  return request({
    url: k8s + '/resource/name',
    method: 'get'
  })
}
// 项目组
// 查询用户项目组列表
export function getUserProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self',
    method: 'get',
    params: data
  })
}
// 查询用户项目组列表创建的
export function getUserOwnerProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self/owner',
    method: 'get',
    params: data
  })
}
// 查询用户项目组列表成员的
export function getUserMemberProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/self/member',
    method: 'get',
    params: data
  })
}
// 创建项目组
export function addProjectGroup(data) {
  return requestAuth({
    url: auth + '/projectGroup/new',
    method: 'post',
    data
  })
}
// 项目组信息
export function queryProjectGroupInfo(data) {
  return requestAuth({
    url: auth + '/projectGroup/info',
    method: 'get',
    params: data
  })
}

// 查询活跃用户
export function queryActiveUser(keyWord) {
  return requestAuth({
    url: authV1 + '/users?state=ACTIVE&keyWord=' + keyWord,
    method: 'get'
  })
}

// 更新项目组
export function updateProjectGroup(data, params) {
  return requestAuth({
    url: auth + '/projectGroup/info',
    method: 'patch',
    data,
    params: params
  })
}
// 获取cpu和Gpu配额
export function getQuota() {
  return request({
    url: k8s + '/resourceLimit/all',
    method: 'get'
  })
}
